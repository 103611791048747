'use client';

import { observer } from 'mobx-react-lite';

import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import { IRatings } from '@/services/models/ReviewsModel';
import type { Nullable } from '@/type-utils';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';

import { Link } from '../../core-ui/Link';
import RatingStars from '../../core-ui/RatingStars';
import { CommonErrorBoundary } from '../../errors/boundaries/CommonErrorBoundary';
import { Price } from '../Price';
import ProductDetailsVariant from '../ProductDetailsVariant';
import VariationSelector from '../VariationSelector';
import { WishlistHeart } from '../WishlistHeart';
import IVariationPanelProps from './IVariationPanelProps';
import { useVariationSettings } from './useVariationSettings';

import S from './styles.base.module.scss';

/** Variation panel to contain size/color selector, add to cart. */
export const AhnuVariationPanel = observer(function AhnuVariationPanel({
  variant = ProductDetailsVariant.Default,
  product,
  children
}: IVariationPanelProps) {
  const {
    isWishlistEnabled,
    isShowStarRatings,
    name,
    sku,
    ratings,
    priceModel
  } = useVariationSettings(variant, product);

  const bottomVariationTypes = [
    DisplayVariationAttributeType.Color,
    DisplayVariationAttributeType.TargetDemographic,
    DisplayVariationAttributeType.Size
  ].filter((variationAttributeType) =>
    /**
     * Filter out any of these that aren't included in the product's attributes.
     * This is to prevent the forced inclusion of a selector that wouldn't work
     * in a particular product display. For example, we want to hide the TargetDemographic
     * selector in a quick view product display. See {@link 'IProductVM' IProductVM} for more information on
     * product displays.
     * */
    product.allVariationTypes.includes(variationAttributeType)
  );

  const topVariationTypes = product.allVariationTypes.filter(
    (type) => !bottomVariationTypes.includes(type)
  );

  // TODO: consider showing a spinner if a product update is loading
  // if (!product) return <Spinner size={30} color="#43A047" />;

  const isPDP = variant === ProductDetailsVariant.Default;

  const swrRatings =
    ratings instanceof StaleWhileRevalidate
      ? (ratings as StaleWhileRevalidate<Nullable<IRatings>>)
      : undefined;

  const ratingsUI =
    ratings instanceof StaleWhileRevalidate ? (
      <RatingStars
        rating={swrRatings?.value?.averageRating ?? 0}
        count={swrRatings?.value?.numberOfRatings}
      />
    ) : undefined;

  return (
    <div className={S.variationPanel}>
      <div className={S.topSection}>
        <div>
          <div className={S.nameSection}>
            {variant === ProductDetailsVariant.Default ||
            variant === ProductDetailsVariant.Exchange ? (
              <h1 className={S.name}>{name}</h1>
            ) : (
              <div className={S.name}>{name}</div>
            )}
            {isWishlistEnabled && <WishlistHeart slug={sku} />}
          </div>
          <Price className={S.price} price={priceModel} />
        </div>
        {isShowStarRatings &&
        swrRatings &&
        !swrRatings.pending &&
        swrRatings.value ? (
          isPDP ? (
            <Link
              as="a"
              href="#reviews"
              className={S.link}
              onClick={() => {
                // TODO: refactor this to use a forward ref
                const el =
                  document.querySelector<HTMLElement>('#reviews button');
                if (el) el.click();
              }}
            >
              {ratingsUI}
            </Link>
          ) : (
            <div>{ratingsUI}</div>
          )
        ) : undefined}
      </div>

      <CommonErrorBoundary>
        {topVariationTypes.map((type) => (
          <VariationSelector key={type} type={type} product={product} />
        ))}

        {/*
        If child content is provided, it should be placed here. This
        would typically be the product carousel, passed in for
        mobile/tablet display.
      */}
        {children}
        <div className={S.bottomSection}>
          {bottomVariationTypes.map((type) => (
            <VariationSelector key={type} type={type} product={product} />
          ))}
        </div>
      </CommonErrorBoundary>
    </div>
  );
});
