import { ArrowVariant } from '@/react/components/content/registry/data/parts/sliderSettings';
import {
  Breakpoint,
  Breakpoints,
  Default
} from '@/react/components/core-ui/Breakpoints';
import { ISliderProps, Slide, Slider } from '@/react/components/Slider';
import type { IProductVM } from '@/react/view-models/ProductVM';
import type { Nullable } from '@/type-utils';
import { observer } from 'mobx-react-lite';
import { Suspense, useMemo, useState } from 'react';
import { Swatch, type ISwatchVariation } from './Swatch';
import { SwatchNavigationButton } from './SwatchNavigationButton';

import S from './styles.base.module.scss';

/**
 * A callback that is called to represent a variation of a swatch.
 *
 * First is always the swatch that was target, and the second is the previous swatch if any.
 */
type SwatchVariationCallback = (
  swatches: [ISwatchVariation, Nullable<ISwatchVariation>]
) => void;

interface IProductSwatchSliderProps {
  /**
   * The swatches to display. These represents the product variations.
   */
  swatches: ReadonlyArray<ISwatchVariation>;

  /**
   * Callback to run on hover. This is called when the user hovers over a swatch.
   */
  onHover?: SwatchVariationCallback;

  /**
   * Callback to run on change. This is called when the user selects a new swatch.
   */
  onChange?: SwatchVariationCallback;

  /**
   * Callback to run on leave. This is called when the user hover leaves a swatch.
   */
  onLeave?: SwatchVariationCallback;

  /**
   * The number of swatches to display per slide. If the number of swatches is greater than this number,
   * the swatches will be displayed in a paginated slider. Defaults to 4.
   *
   * @default 4
   */
  swatchesPerSlide: number;
  /**
   * The active swatch if it comes from outside the component.
   * The state can be managed entirely by the component if color is the only attribute
   * being maintained, but once you need more you will probably want to manage state using
   * this prop.
   */
  activeSwatch?: ISwatchVariation;
}

/**
 * A slider that displays swatches for a product.
 */
export const ProductSwatchSlider = observer(function ProductSwatchSlider({
  swatches,
  onHover,
  onChange,
  onLeave,
  swatchesPerSlide = 4,
  activeSwatch
}: IProductSwatchSliderProps) {
  const [selectedSwatch, setSelectedSwatch] = useState<
    Nullable<ISwatchVariation>
  >(activeSwatch ?? null);

  /**
   * This is the currently active hex value based on how the state of this component is
   * being managed. If active swatch is being used at all it will take precedence, if not
   * the component will manage this internally.
   */
  const activeHexValue = useMemo(() => {
    return activeSwatch ? activeSwatch?.hexValue : selectedSwatch?.hexValue;
  }, [activeSwatch, selectedSwatch]);

  // Width factor to calculate the width of the slider.
  // Represented by the fixed 32px width of the swatch plus the 8px spacing between each swatch.
  const widthFactor = 40;

  // Calculate the width of the slider based on the number of swatches to ensure that the slider is responsive.
  // The navigation buttons are not considered in the calculation because the inset is false.
  const widthString = `${widthFactor * swatchesPerSlide}px`;

  // Base slider configuration shared between mobile and desktop.
  const baseConfig = {
    height: 'auto',
    slidesPerView: swatchesPerSlide,
    slidesPerGroup: swatchesPerSlide,
    centeredSlides: false,
    direction: 'horizontal',
    spaceBetween: 8,
    sliderClassName: S.wrapper,
    loop: false,
    pagination: {
      enabled: false
    },
    style: {
      width: widthString
    }
  };

  const mobileSliderConfig = {
    ...baseConfig,
    allowTouchMove: true,
    navigation: {
      enabled: false
    }
  } as ISliderProps;

  const desktopSliderConfig = {
    ...baseConfig,
    allowTouchMove: swatches.length > swatchesPerSlide,
    navigation: {
      inset: false,
      arrowVariant: ArrowVariant.AngleLight,
      buttonIconComponent: SwatchNavigationButton,
      buttonClassName: S.navigationButton
    }
  } as ISliderProps;

  return (
    <div className={S.sliderWrapper}>
      <Suspense>
        <Breakpoints>
          <Breakpoint media={['desktop']}>
            <Slider {...desktopSliderConfig}>
              {swatches.map((swatch) => (
                <Slide key={`swatch--${swatch.sku}`}>
                  <Swatch
                    {...swatch}
                    active={swatch.hexValue === activeHexValue}
                    onHover={() => onHover?.([swatch, selectedSwatch])}
                    onLeave={() => onLeave?.([swatch, selectedSwatch])}
                    onClick={() => {
                      setSelectedSwatch((prev) => {
                        onChange?.([swatch, prev]);
                        return swatch;
                      });
                    }}
                  />
                </Slide>
              ))}
            </Slider>
          </Breakpoint>
          <Default>
            <Slider {...mobileSliderConfig}>
              {swatches.map((swatch) => (
                <Slide key={`swatch--${swatch.sku}`}>
                  <Swatch
                    {...swatch}
                    active={swatch.hexValue === activeHexValue}
                    onHover={() => onHover?.([swatch, selectedSwatch])}
                    onLeave={() => onLeave?.([swatch, selectedSwatch])}
                    onClick={() => {
                      setSelectedSwatch((prev) => {
                        onChange?.([swatch, prev]);
                        return swatch;
                      });
                    }}
                  />
                </Slide>
              ))}
            </Slider>
          </Default>
        </Breakpoints>
      </Suspense>
    </div>
  );
});
