'use client';

import { observer } from 'mobx-react-lite';
import { Select, type ISelectOption } from '@/react/components/core-ui/Select';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import {
  DisplayVariationAttributeType,
  type IProductVM
} from '@/react/view-models/ProductVM';
import { ProductAvailabilityState } from '@/services/models/Inventory';
import { product_details_outOfStock } from "@/lang/__generated__/ahnu/product_details_outOfStock";
import { product_details_addToCart_addSize } from "@/lang/__generated__/ahnu/product_details_addToCart_addSize";

export interface ISizeDropdownProps {
  /** The product view model being used in the parent size selector. */
  product: IProductVM;
}

export const SizeDropdown = observer(function SizeDropdown({
  product
}: ISizeDropdownProps) {
  const [msg] = useLocaleMessage();

  const sizes = product.getVariationAttributesByType(
    DisplayVariationAttributeType.Size
  );

  const options: Array<ISelectOption> = sizes.map((size) => {
    const variantAvailability =
      product.determineResultingAvailabilityOnAttributeSelection(
        size.type,
        size.value
      );

    // As we add more functionality for availability, we can adjust this to match requirements.
    const isOutOfStockOrUnavailable =
      variantAvailability !== ProductAvailabilityState.InStock;

    return {
      label: isOutOfStockOrUnavailable
        ? `${size.metadata.name} - ${msg(product_details_outOfStock)}`
        : size.metadata.name,
      value: size.value
    };
  });

  options.unshift({
    label: msg(product_details_addToCart_addSize),
    value: '',
    // Disabled the default value to create the desired UX experience.
    disabled: true
  });

  return (
    <Select
      label={msg(product_details_addToCart_addSize)}
      showLabel={false}
      options={options}
      value={product.selectedVariations.size ?? ''}
      onChange={async (e) =>
        product.selectVariationAttribute(
          DisplayVariationAttributeType.Size,
          e.target.value
        )
      }
    />
  );
});
