'use client';

import { Image } from '@/react/components/core-ui/Image';
import type { IProductVM } from '@/react/view-models/ProductVM';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { msg } from '@/services/isomorphic/I18NService';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import { IDisplayColorVariationAttribute } from '@/react/view-models/ProductVM/IDisplayVariationAttribute';
import { ProductAvailabilityState } from '@/services/models/Inventory';
import { maybe } from '@/utils/null-utils';
import { observer } from 'mobx-react-lite';
import S from './styles.base.module.scss';
import { product_details_color } from "@/lang/__generated__/ahnu/product_details_color";

export interface IColorButtonProps {
  /** The color variation attribute. */
  color: IDisplayColorVariationAttribute;

  /** The product view model being used in the parent color selector. */
  product: IProductVM;

  /** Show image swatches instead of color swatches.  */
  isImageSwatchesEnabled: boolean;
}

/** An individual color button in a {@link ColorSelector Color Selector}. */
export const ColorButton = observer(function ColorButton({
  color,
  product,
  isImageSwatchesEnabled
}: IColorButtonProps) {
  const { type, value, isSelectable, isSelected, metadata } = color;

  const variantAvailability =
    product.determineResultingAvailabilityOnAttributeSelection(type, value);

  const isOutOfStockOrUnavailable =
    !isSelected && // Don't show OOS styling if selected
    (variantAvailability === ProductAvailabilityState.OutOfStock ||
      variantAvailability === ProductAvailabilityState.Unavailable);

  return (
    <button
      type="button"
      className={classes(S.color, {
        [S.outOfStock]: isOutOfStockOrUnavailable,
        [S.selected]: isSelected,
        [S.unselectable]: !isSelectable || isOutOfStockOrUnavailable
      })}
      // If image swatches are disabled or if there is no swatch image on
      // the color, fallback to color hex value.
      style={
        !isImageSwatchesEnabled || !metadata.swatchImage
          ? { backgroundColor: metadata.hexValue }
          : {}
      }
      key={value}
      onClick={async () => product.selectVariationAttribute(type, value)}
      aria-label={metadata.name}
    >
      {isImageSwatchesEnabled && metadata.swatchImage && (
        <Image
          // Do not use a skeleton here since these images have transparent
          // backgrounds.
          useSkeletonLoader={false}
          fill
          image={{
            src: metadata.swatchImage.src,
            alt: `${metadata.swatchImage.alt}`,
            uuid: `swatch-${metadata.swatchImage.uuid}`
          }}
        />
      )}
    </button>
  );
});

export interface IColorSelectorProps {
  /** The product view model to display color options for. */
  product: IProductVM;
  /** The variant of the color selector to render. */
  variant?: ColorSelectorVariant;
  /** Show selected color above the selector. */
  showCurrent?: boolean;
  /** Show image swatches instead of color swatches.  */
  isImageSwatchesEnabled?: boolean;
}

/**
 * The variant of the color selector to render.
 */
export enum ColorSelectorVariant {
  Default = 'default',
  Tile = 'tile'
}

/** The Color selector component for the PDP. */
export const ColorSelector = observer(function ColorSelector({
  product,
  variant = ColorSelectorVariant.Default,
  showCurrent = true,
  isImageSwatchesEnabled
}: IColorSelectorProps) {
  const colors = product
    .getVariationAttributesByType(DisplayVariationAttributeType.Color)
    .slice(0); // Clone the array to avoid modifying the original.

  colors.sort((a, b) => a.metadata.name.localeCompare(b.metadata.name));

  // Get the selected color.
  const selectedColor = maybe(colors.filter((color) => color.isSelected)[0]);

  // Get the swatch configuration.
  const swatchConfig =
    ConfigurationService.getConfig('product').getSetting('swatches');

  const isColorSwatchSliderEnabled =
    swatchConfig.enableMobileSwatchSlider.value;

  // fallback to the swatch config value if not provided
  isImageSwatchesEnabled ??= swatchConfig.enableImageSwatchesPDP.value;

  return (
    <div
      className={classes(
        S.container,
        variant === ColorSelectorVariant.Tile && S.tileSelector
      )}
    >
      {showCurrent && (
        <div className={S.currentColor}>
          {msg(product_details_color)}
          <span className={S.value}>
            {selectedColor ? selectedColor.metadata.name : ''}
          </span>
        </div>
      )}
      <div
        className={classes(S.colors, isColorSwatchSliderEnabled && S.colorsRow)}
      >
        {colors.map((color) => (
          <ColorButton
            key={color.value}
            color={color}
            product={product}
            isImageSwatchesEnabled={isImageSwatchesEnabled}
          />
        ))}
      </div>
    </div>
  );
});
