import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import ProductService from '@/services/isomorphic/ProductService';
import { ProductAvailabilityState } from '@/services/models/Inventory';
import { IProduct, ProductModel } from '@/services/models/Product';
import { VariationAttributeType } from '@/services/models/Product/variation-attributes';
import type { DTO, Nullable } from '@/type-utils';
import { InvalidArgumentError } from '@/utils/errors';
import { isNullOrEmpty } from '@/utils/null-utils';
import { removeUndefined } from '@/utils/object-utils';
import DisplayVariationAttributeType from '../DisplayVariationAttributeType';
import type {
  DisplayVariationAttributeFromType,
  IDisplayColorVariationAttribute,
  IDisplaySizeVariationAttribute
} from '../IDisplayVariationAttribute';
import type IProductDisplay from '../IProductDisplay';

interface IProductAdapterProps {
  /**
   * The product to adapt.
   */
  readonly product: IProduct | DTO<IProduct>;
}

/**
 * An adapter for {@link IProduct} to implement the {@link IProductDisplay} interface.
 */
class ProductAdapter implements IProductDisplay {
  private readonly product: ProductModel;

  /**
   * Creates a new product adapter.
   * @param product - The product to adapt.
   */
  private constructor({ product }: IProductAdapterProps) {
    this.product = ProductModel.from(product);
  }

  /** @inheritdoc */
  public getVariationsByType<T extends DisplayVariationAttributeType>(
    type: T
  ): ReadonlyArray<DisplayVariationAttributeFromType<T>> {
    const selectedAttributes = this.selectedVariations;

    switch (type) {
      case DisplayVariationAttributeType.Color: {
        const selectableColors = this.product
          .getSelectableAttributesByType(VariationAttributeType.Color)
          .map((attr) => attr.value);

        return this.product
          .getAvailableAttributesByType(VariationAttributeType.Color)
          .map((attr) => {
            return {
              type: DisplayVariationAttributeType.Color,
              value: attr.value,
              isSelectable: selectableColors.includes(attr.value),
              isSelected:
                attr.value ===
                selectedAttributes[DisplayVariationAttributeType.Color],
              metadata: attr
            } as IDisplayColorVariationAttribute as DisplayVariationAttributeFromType<T>;
          });
      }
      case DisplayVariationAttributeType.Size: {
        const selectableSizes = this.product
          .getSelectableAttributesByType(VariationAttributeType.Size)
          .map((attr) => attr.value);

        return (
          this.product
            .getAvailableAttributesByType(VariationAttributeType.Size)
            .map((attr) => {
              return {
                type: DisplayVariationAttributeType.Size,
                value: attr.value,
                isSelectable: selectableSizes.includes(attr.value),
                isSelected:
                  attr.value ===
                  selectedAttributes[DisplayVariationAttributeType.Size],
                metadata: attr
              } as IDisplaySizeVariationAttribute;
            })
            // ensure that the sizes are sorted by numerically
            .sort((sizeA, sizeB) => {
              const positionA = sizeA.metadata.position;
              const positionB = sizeB.metadata.position;

              // sort `undefined` positions to the end
              return positionA !== undefined
                ? positionB !== undefined
                  ? positionA - positionB
                  : -1
                : 1;
            }) as Array<DisplayVariationAttributeFromType<T>>
        );
      }
      default:
        throw new InvalidArgumentError(
          `The variation type "${type}" is not part of this product display.`
        );
    }
  }

  /** @inheritdoc */
  public get selectedVariations(): {
    [DisplayVariationAttributeType.Color]?: string | undefined;
    [DisplayVariationAttributeType.Size]?: string | undefined;
  } {
    // Note: `removeUndefined` is necessary here because `selectedVariations` are
    // frequently used with spread syntax, and `undefined` values will overwrite
    // existing values in the spread, unless the key is removed.
    return removeUndefined({
      [DisplayVariationAttributeType.Color]:
        this.product.getSelectedAttributeByType(VariationAttributeType.Color)
          ?.value,
      [DisplayVariationAttributeType.Size]:
        this.product.getSelectedAttributeByType(VariationAttributeType.Size)
          ?.value
    });
  }

  /** @inheritdoc */
  public get availability(): ProductAvailabilityState {
    return this.product.availabilityState;
  }

  /** @inheritdoc */
  public get isPurchasable(): boolean {
    const { availability } = this;

    const inventoryConfig = ConfigurationService.getConfig('inventory');
    const allowBackorder = inventoryConfig.getSetting('allowBackorder').value;
    const allowPreorder = inventoryConfig.getSetting('allowPreorder').value;

    const stockAvailable =
      availability === ProductAvailabilityState.InStock ||
      (allowBackorder && availability === ProductAvailabilityState.Backorder) ||
      (allowPreorder && availability === ProductAvailabilityState.Preorder);

    return (
      stockAvailable &&
      this.product.isCompleteVariant &&
      !isNullOrEmpty(this.product.upc)
    );
  }

  /** @inheritdoc */
  public get allVariationTypes(): ReadonlyArray<DisplayVariationAttributeType> {
    const result = [] as Array<DisplayVariationAttributeType>;

    const { availableAttributeTypes } = this.product;

    if (availableAttributeTypes.includes(VariationAttributeType.Color)) {
      result.push(DisplayVariationAttributeType.Color);
    }

    if (availableAttributeTypes.includes(VariationAttributeType.Size)) {
      result.push(DisplayVariationAttributeType.Size);
    }

    return result;
  }

  /** @inheritdoc */
  public get currentProduct(): IProduct {
    return this.product;
  }

  /** @inheritdoc */
  public determineResultingOnlineStatusOnAttributeSelection(
    variationType: DisplayVariationAttributeType,
    value: string
  ): boolean {
    if (!this._isVariationAttributeType(variationType)) {
      throw new InvalidArgumentError(
        `The variation type "${variationType}" is not part of this product display.`
      );
    }

    const updatedSelectedAttributes = {
      ...this.selectedVariations,
      // this is where the "greedy" selection happens, since we prioritize the newer selection.
      [variationType]: value
    };

    const newColor =
      updatedSelectedAttributes[DisplayVariationAttributeType.Color];
    const newSize =
      updatedSelectedAttributes[DisplayVariationAttributeType.Size];

    const updatedSelectionAttributesAsProductVariationAttributes = {
      [VariationAttributeType.Color]: !isNullOrEmpty(newColor)
        ? (`color-${newColor}` as const)
        : undefined,
      [VariationAttributeType.Size]: !isNullOrEmpty(newSize)
        ? (`size-${newSize}` as const)
        : undefined
    };

    return ProductService.getProductOnlineStatusBySelectedAttributes(
      this.currentProduct,
      updatedSelectionAttributesAsProductVariationAttributes
    );
  }

  /** @inheritdoc */
  public async unselectVariation(
    variationType: DisplayVariationAttributeType
  ): Promise<ProductAdapter> {
    return this.selectVariation(variationType);
  }

  /** @inheritdoc */
  public async selectVariation(
    variationType: DisplayVariationAttributeType,
    value?: string | undefined
  ): Promise<ProductAdapter> {
    if (!this._isVariationAttributeType(variationType)) {
      throw new InvalidArgumentError(
        `The variation type "${variationType}" is not part of this product display.`
      );
    }

    const updatedSelectedAttributes = {
      ...this.selectedVariations,
      // this is where the "greedy" selection happens, since we prioritize the newer selection.
      [variationType]: value
    };

    const newColor =
      updatedSelectedAttributes[DisplayVariationAttributeType.Color];
    const newSize =
      updatedSelectedAttributes[DisplayVariationAttributeType.Size];

    const updatedSelectionAttributesAsProductVariationAttributes = {
      [VariationAttributeType.Color]: !isNullOrEmpty(newColor)
        ? (`color-${newColor}` as const)
        : undefined,
      [VariationAttributeType.Size]: !isNullOrEmpty(newSize)
        ? (`size-${newSize}` as const)
        : undefined
    };

    const newSKU = ProductService.getProductSKUBySelectedAttributes(
      this.currentProduct,
      updatedSelectionAttributesAsProductVariationAttributes
    );

    if (isNullOrEmpty(newSKU)) {
      throw new InvalidArgumentError(
        `Could not find a variant for the selected attributes: ${JSON.stringify(
          updatedSelectedAttributes
        )}`
      );
    }

    const product = await ProductService.getProduct(newSKU);

    return new ProductAdapter({ product });
  }

  /** @inheritdoc */
  public determineResultingUpcOnAttributeSelection(
    variationType: DisplayVariationAttributeType,
    value: string
  ): Nullable<string> {
    if (!this._isVariationAttributeType(variationType)) {
      throw new InvalidArgumentError(
        `The variation type "${variationType}" is not part of this product display.`
      );
    }

    const updatedSelectedAttributes = {
      ...this.selectedVariations,
      // this is where the "greedy" selection happens, since we prioritize the newer selection.
      [variationType]: value
    };

    const newColor =
      updatedSelectedAttributes[DisplayVariationAttributeType.Color];
    const newSize =
      updatedSelectedAttributes[DisplayVariationAttributeType.Size];

    const updatedSelectionAttributesAsProductVariationAttributes = {
      [VariationAttributeType.Color]: !isNullOrEmpty(newColor)
        ? (`color-${newColor}` as const)
        : undefined,
      [VariationAttributeType.Size]: !isNullOrEmpty(newSize)
        ? (`size-${newSize}` as const)
        : undefined
    };

    return ProductService.getProductUPCBySelectedAttributes(
      this.currentProduct,
      updatedSelectionAttributesAsProductVariationAttributes
    );
  }

  /**
   * Creates a new product adapter from a product.
   * @param product - The product to create the adapter from.
   * @returns The created product adapter.
   */
  public static fromProduct(product: IProduct | DTO<IProduct>): ProductAdapter {
    return new ProductAdapter({ product });
  }

  /**
   * Checks if the given value is a valid variation attribute type.
   * @param value - The value to check.
   * @returns `true` if the value is a valid variation attribute type, otherwise `false`.
   */
  private _isVariationAttributeType(
    value: string
  ): value is DisplayVariationAttributeType {
    return (
      value === DisplayVariationAttributeType.Color ||
      value === DisplayVariationAttributeType.Size
    );
  }

  /** @inheritdoc */
  public withCurrentProduct(product: IProduct | DTO<IProduct>): ProductAdapter {
    if (product.styleNumber !== this.product.styleNumber) {
      throw new InvalidArgumentError(
        `Received product "${product.sku}" is not part of this product display.`
      );
    }

    return new ProductAdapter({ product });
  }

  /** @inheritdoc */
  public get variationUPCs(): ReadonlyArray<string> {
    return this.product.variationUPCs;
  }
}

export default ProductAdapter;
