'use client';

import { observer } from 'mobx-react-lite';
import type { IProductVM } from '@/react/view-models/ProductVM';
import type { IDisplaySizeVariationAttribute } from '@/react/view-models/ProductVM/IDisplayVariationAttribute';
import { ProductAvailabilityState } from '@/services/models/Inventory';
import { classes } from '@/next-utils/css-utils/scss-utils';

import S from './styles.base.module.scss';

export interface ISizeButtonProps {
  /** The size variation attribute. */
  size: IDisplaySizeVariationAttribute;

  /** The product view model being used in the parent size selector. */
  product: IProductVM;
}

/** An individual size button in a {@link SizeSelector Size Selector}. */
export const SizeButton = observer(function SizeButton({
  size,
  product
}: ISizeButtonProps) {
  const {
    type,
    value,
    isSelectable,
    isSelected,
    metadata: { name }
  } = size;

  /** Check the availability state of the selected variation(s) of the current product. */
  const variantAvailability =
    product.determineResultingAvailabilityOnAttributeSelection(type, value);

  /** Check to see if the selected product variant(s) are Out of Stock or Unavailable. */
  const isOutOfStockOrUnavailable =
    variantAvailability === ProductAvailabilityState.OutOfStock ||
    variantAvailability === ProductAvailabilityState.Unavailable;

  return (
    <button
      type="button"
      className={classes(S.size, {
        [S.outOfStock]: isOutOfStockOrUnavailable,
        [S.selected]: isSelected,
        [S.unselectable]: !isSelectable || isOutOfStockOrUnavailable
      })}
      key={value}
      onClick={async () => product.selectVariationAttribute(type, value)}
      data-testid="pdpProductSize"
    >
      {name}
    </button>
  );
});
