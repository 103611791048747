'use client';

import { RecommendationContext } from '@/configs/recommendations';
import { Nullable } from '@/type-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { PageType, type IPage } from '@/services/models/Page';
import { IProduct } from '@/services/models/Product';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import ProductService from '@/services/isomorphic/ProductService';
import RecommendationService from '@/services/isomorphic/RecommendationService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { PageContext } from '../components/templates/context/PageContext';

/** `useRecommendedProducts` returning interface. */
interface IUseRecommendedProducts {
  /** A list of recommended products. */
  products: Array<IProduct>;

  /** A title to display above the recommendations. */
  title: Nullable<string>;

  /** Is the current recommendation context currently enabled. */
  isRecommendationEnabled: boolean;

  /** Is the current context set to slide on mobile, which is an alternate mobile style. */
  isSlideOnMobile: boolean;
}

/**
 * `useRecommendedProducts` fetches recommended products given an context.
 * @param context - The context to display the products.
 * @param numberOfProducts - The number of products to be fetched.
 * @param recommendedProducts - When rendering the `Custom404Page` from the `GenericPage`
 * the props `recommendedProducts` will be undefined since it is fetched in a static manner.
 * Therefore, we need to conditionally check it before actually fetch recommended products again.
 * @returns - A list of recommended products.
 */
export const useRecommendedProducts = (
  context: RecommendationContext,
  numberOfProducts?: number,
  recommendedProducts?: Array<IProduct>
): IUseRecommendedProducts => {
  const { isRecommendationEnabled, isSlideOnMobile } =
    RecommendationService.getContextConfig(context);

  const [products, setProducts] = useState(
    isRecommendationEnabled ? recommendedProducts : []
  );
  const [title, setTitle] = useState<string | null>(null);

  const page = useContext(PageContext)?.page;

  useEffect(
    function getRecommendedProducts() {
      if (!isRecommendationEnabled) return;
      if (products !== undefined) return;

      const fetchProducts = async (): Promise<void> => {
        const { productList, title } =
          await RecommendationService.getRecommendations(
            context,
            page ?? {
              pageType: PageType.Unknown,
              url: EnvironmentService.url.origin
            }
          );

        const getProductPromises = productList
          .slice(0, numberOfProducts)
          .map(async (sku) => {
            return ProductService.getProductDTO(sku).catch(() => {
              LoggerService.error(`Cannot find product with SKU: ${sku}`);
              return null;
            });
          });

        const recommendedSettledResult =
          await Promise.allSettled(getProductPromises);
        const recommendedProds = recommendedSettledResult
          .map((result) =>
            result.status === 'fulfilled' ? result.value : null
          )
          .filter((result) => result !== null);

        setTitle(title);
        setProducts(recommendedProds);
      };

      // eslint-disable-next-line no-void -- Initiate the fetch call.
      void fetchProducts();
    },
    [context, isRecommendationEnabled, numberOfProducts, page, products]
  );

  return {
    title,
    products: products ?? [],
    isRecommendationEnabled,
    isSlideOnMobile
  };
};
