'use client';

import { CSSProperties, FunctionComponent } from 'react';

import classNames from 'classnames';
import { setupPreviews } from '@previewjs/plugin-react/setup';
import { Icon, IconTypes } from '../Icon';
import S from './styles.base.module.scss';

export interface IRatingStarsProps {
  /** The rating score (a real number between 1 and 5) to display. */
  rating: number;

  /** Number of ratings for a given product. */
  count?: number;

  /** Custom star size in pixels. Will be applied to both height and width. Takes priority over all styles. */
  starSize?: number;

  /** Custom star color. Takes priority over all styles. */
  starColor?: string;

  /** CSS class name(s) for the container. */
  containerClassName?: string;

  /** CSS ID for the container. */
  containerId?: string;

  /** Inline styles for the container.  */
  containerStyle?: {
    [key: string]: CSSProperties;
  };

  /** CSS class name(s) for the stars. */
  starClassName?: string;

  /** CSS ID for the stars. */
  starId?: string;

  /** Inline styles for the stars.  */
  starStyle?: {
    [key: string]: CSSProperties;
  };
}

/**
 * Displays the stars for a given rating score.
 */
const RatingStars: FunctionComponent<IRatingStarsProps> = ({
  rating,
  count,
  containerStyle,
  starSize,
  starColor,
  containerClassName,
  containerId,
  starClassName,
  starId,
  starStyle
}) => {
  // Calculate the amount of each type of star to display.

  // Full star count = rating score rounded down.
  let fullStars: number = Math.floor(rating);

  // True if there is a half star separating the full stars from the empty stars.
  // A half star will only be present if the rating score decimals are greater than 0.5.
  let halfStar: boolean = rating - fullStars >= 0.5;

  // Empty star amount = max star count - full star count - 1 if there is a half star present.
  let emptyStars: number = 5 - fullStars - (halfStar ? 1 : 0);

  /**
   * Generator function that yields the icon definitions to match the calculated values.
   * @yields Five icon definitions to render.
   */
  function* starGenerator(): Generator<IconTypes> {
    while (fullStars > 0) {
      // Subtract to the full star count so it stops entering
      // the while loop after rendering all the full stars.
      fullStars -= 1;
      yield IconTypes.FullStar;
    }

    if (halfStar) {
      halfStar = false;
      yield IconTypes.HalfStar;
    }

    while (emptyStars > 0) {
      emptyStars -= 1;
      yield IconTypes.EmptyStar;
    }

    // fullStars = 0, emptyStars = 0, halfStar = false
    // This generator won't yield any more.
  }

  return (
    <div
      className={classNames(S.ratingStars, containerClassName)}
      id={containerId}
      style={{
        ...containerStyle,

        // Apply height if `starSize` is present.
        ...(starSize && {
          height: `${starSize}px`
        })
      }}
    >
      {/* Map eveything that the generator will yield. (5 star icon definitions) */}
      {Array.from(starGenerator()).map((Star, i) => (
        <Icon
          icon={Star}
          className={classNames(S.star, starClassName)}
          // eslint-disable-next-line react/no-array-index-key -- Star icons are only differentiated by number.
          key={`star-${i}`}
          style={{
            ...starStyle,

            // Apply width and height if `starSize` is present.
            ...(starSize && {
              width: `${starSize}px`,
              height: `${starSize}px`
            }),

            // Apply custom color only if `starColor` is present.
            ...(starColor && { color: starColor })
          }}
        />
      ))}
      {
        // Ratings number.
        count && <span className={S.count}>{`(${count})`}</span>
      }
    </div>
  );
};

export default RatingStars;

/** @see https://previewjs.com/docs/features/custom-previews */
setupPreviews(RatingStars, () => ({
  default: {
    rating: 1
  } as IRatingStarsProps
}));
