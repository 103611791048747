import { RecommendationContext } from '@/configs/recommendations';
import { InvalidArgumentError } from '@/utils/errors';
import { exhaustiveFallback } from '@/utils/function-utils';
import Service from '../../Service';

import { IPage } from '../../models/Page';
import { siteCached } from '../../utils/siteCached/siteCached';
import { Config } from '../ConfigurationService';
import ConfigurationService from '../ConfigurationService/ConfigurationService';
import LoggerService from '../LoggerService';
import { PersonalizationService } from '../PersonalizationService';
import { IRecommendationContextConfig } from './IRecommendationContextConfig';
import IRecommendationsData from './IRecommendationsData';
import RecommendationServiceMock, {
  MOCK_RECOMMENDATION
} from './RecommendationServiceMock';
import { getContextConfig } from './getContextConfig';

/**
 * RecommendationService handle the retrieval of recommendation products from the DY.
 * Integration Service.
 */
export class RecommendationService extends Service {
  /**
   * The Recommendations config.
   * @returns A `Config<'recommendations'>`.
   */
  @siteCached
  private get config(): Config<'recommendations'> {
    return ConfigurationService.getConfig('recommendations');
  }

  /**
   * The Dynamic Yield config.
   * @returns A `Config<'dynamicYield'>`.
   */
  @siteCached
  private get dyConfig(): Config<'dynamicYield'> {
    return ConfigurationService.getConfig('dynamicYield');
  }

  /** Initializes the recommendation service. */
  public constructor() {
    super();
  }

  /**
   * Gets the recommendation config values for a given context.
   * @param context - The recommendation context.
   * @returns - An object holding values useful to the frontend.
   * @throws If an invalid context is supplied.
   */
  public getContextConfig(
    context: RecommendationContext
  ): IRecommendationContextConfig {
    const { isRecommendationEnabled, isSlideOnMobile } =
      getContextConfig(context);
    return { isRecommendationEnabled, isSlideOnMobile };
  }

  /**
   * `getRecommendationsForCurrentUser` stablish the communication with DYService to
   * retrieve recommendation products based in a context: HOMEPAGE, PDP, CART, ACCOUNT.
   * @param context - The context we want to retrieve the recommendation products.
   * @param page - The current page the user is on.
   * @returns A list of product SKUs with a title.
   */
  public async getRecommendations(
    context: RecommendationContext,
    page: IPage
  ): Promise<IRecommendationsData> {
    const isDYEnabled = this.dyConfig.getSetting('isDYEnabled').value;

    const fallbackRecommendations = {
      title: '',
      productList: []
    };

    // We currently have no default way of getting recommendations.
    // So if DY is not enabled then we return an empty list.
    if (!isDYEnabled) {
      return fallbackRecommendations;
    }

    /**
     * Gets the direct recomendation experience and pulls a list of products off of it.
     * In the future we will use the dynamic recommenations.
     */
    const data = await PersonalizationService.getRecommendationsForCurrentUser(
      context,
      page
    );

    return data;
  }
}

export default RecommendationService.withMock(
  new RecommendationServiceMock(RecommendationService)
) as unknown as RecommendationService;
